<template>
    <v-card>
        <v-card-title primary-title>
            <v-icon left large>mdi-cash-usd-outline</v-icon>
            <span class="headline">Confirmar Ventas</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>

          <v-row>
            <v-col class="pb-0 pt-0 " cols="4" md="2">
                  <v-text-field
                    v-model="filtro_pedido"
                    label="# Pedido"
                    append-icon="mdi-magnify"
                    clearable
                    @click:clear="clearFiltroPedido"
                    @keydown.enter="getDataFromApi"
                ></v-text-field>
            </v-col>
            <v-col class="pb-0 pt-0 " cols="8" md="4">
              <v-text-field
                v-model="filtro_vendedor"
                append-icon="mdi-magnify"
                label="Buscar vendedor (escribe mas de 2 letras)"
                hide-details
                clearable
                @click:clear="clearFiltroVendedor"
                @keydown.enter="getDataFromApi"
              ></v-text-field>
            </v-col>
            <v-col class="pb-0 pt-0 " cols="12" md="4">
             
              <v-select
                  v-model="filtro_idrepartidor"
                  :items="repartidores"
                  item-text="nombre"
                  item-value="idusuario"
                  menu-props="auto"
                  label="Selecciona al repartidor"
                  hide-details
                  prepend-icon="mdi-face-outline"
                  single-line
                  @change="getDataFromApi"
                  @click:clear="clearFiltroRepartidor"
                  clearable
              ></v-select>
              
            </v-col>
            <!-- <v-col cols="6" md="2" outline>
                  <v-btn color="primary" depressed @click="getDataFromApi" icon><v-icon>mdi-filter</v-icon></v-btn>
                  <v-btn color="primary" depressed @click="getDataFromApi" icon><v-icon>mdi-refresh</v-icon></v-btn>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" md="2" class="indigo--text">
              <span v-if="grid_rows"><strong>Pedidos encontrados:</strong> {{ grid_rows.length }}</span>
            </v-col>
            <v-col cols="12" md="2" v-if="pedidos_notfound.length > 0" class="red--text">
              <strong>No encontrados:</strong><span v-for="id in pedidos_notfound" :key="id"> {{ id }},</span>              
            </v-col>
            <v-col cols="12" md="3">
              <span v-if="grid_rows"><strong>Total Cuenta x Cobrar:</strong> {{ totalCuentasXCobrar.toLocaleString() }}</span>
            </v-col>
            <v-col cols="12" md="2">
              <span v-if="grid_rows"><strong>Pares x Regresar:</strong> {{ productos_regresar.length }}</span>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn color="primary" depressed @click.stop="dialogMercancia = true" icon><v-icon>mdi-widgets-outline</v-icon></v-btn>
            </v-col>

            <v-spacer></v-spacer>

             <v-col cols="12" md="1">
              <v-btn color="primary" depressed @click.stop="dialogPrefijos = true" icon><v-icon>mdi-format-list-bulleted-square</v-icon></v-btn>
            </v-col>

            <v-dialog
                v-model="dialogMercancia"
                max-width="600"
                scrollable
              >
                <v-card>
                  <v-card-title class="headline">Pares x regresar: {{ productos_regresar.length }}</v-card-title>
                  <v-divider></v-divider>

                  <v-card-text>
                    <v-list>
                      <v-list-item v-for="item in productos_regresar" :key="item.id">
                        <v-list-item-content>
                          <span v-if="item.es_cambio">
                            <b>CAMBIO:</b> {{ item.cambio_codigo }}
                          </span>
                          <span v-else>{{ item.descripcion }} - {{ item.codigo }}</span>
                          
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                     
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogMercancia = false"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            <v-dialog
                v-model="dialogPrefijos"
                max-width="290"
              >
                <v-card>
                  <v-card-title class="headline">Totales x Prefijo</v-card-title>

                  <v-card-text>
                    
                     <v-data-table
                        :headers="dt_prefijos_headers"
                        :items="totales_prefijos"
                        disable-filtering
                        disable-pagination
                        hide-default-footer
                        disable-sort
                        item-key="prefijo"
                        no-data-text="No se encontraron prefijos"
                        loading-text="Buscando totales"
                        >
                    </v-data-table>  
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogPrefijos = false"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-row>
          
          <v-row>
            <v-col cols="12">
              <v-data-table
                  :headers="headers"
                  :items="grid_rows"
                  :loading="isLoading"
                  :options.sync="options"
                  disable-filtering
                  disable-pagination
                  hide-default-footer
                  disable-sort
                  :server-items-length="totalgrid_rows"
                  item-key="id"
                  no-data-text="No se encontraron pedidos"
                  loading-text="Buscando pedidos"
                  :must-sort="true"
                  @click:row="itemSelected"
                  >
                  <template v-slot:item.cuentaxcobrar="{ item }">
                    <v-chip :color="item.cuentaxcobrar < 0 ? 'red' : 'orange'" dark v-if="item.venta_confirmada">{{ item.cuentaxcobrar }}</v-chip>
                    <v-chip v-if="item.cancelado == 1" color="red" dark>C</v-chip>
                  </template>
              </v-data-table>  
            </v-col>
          </v-row>
        </v-card-text>

        <v-dialog v-model="dialogEditar" scrollable max-width="1100px">
            
            <v-card color="grey lighten-5">
                <v-card-title>
                    <span class="title">{{ formTitle }}</span>
                    <v-spacer></v-spacer>
                    <span class="title" v-if="isDesktop">{{ fechahoraPedido }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        
                        <v-col cols="12" md="4" class="pt-0">
                          <v-row>
                            <v-col cols="6" class="pt-0">
                             <v-checkbox v-model="cancelado" label="Cancelado"></v-checkbox> 
                            </v-col>
                            <v-col cols="6" md="4" class="pt-0">
                              <v-checkbox v-model="mayoreo" label="Mayoreo"></v-checkbox> 
                            </v-col>
                          </v-row>
                          <v-row v-if="cancelado">
                            <v-col cols="12" class="pt-0">
                              <v-textarea
                              filled
                              label="Motivo de cancelación"
                              auto-grow
                              v-model="editedItem.motivo_cancelacion"
                              ></v-textarea>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" class="pt-0">
                              <v-textarea
                              name="txtresumen"
                              filled
                              label="Resumen"
                              outlined
                              auto-grow
                              v-model="resumen_pedido"
                              readonly=""
                              ></v-textarea>

                            </v-col>
                          </v-row>
                         
                        </v-col>

                        <v-col cols="12" md="8" class="pt-0">
                            <v-list subheader color="transparent">
                                <v-subheader>Entregados al Cliente</v-subheader>

                                <v-list-item v-for="item in entregables" :key="item.id" >
                                    <!-- <v-icon left v-if="item.entregado">mdi-checkbox-marked-outline</v-icon>
                                    <v-icon left v-else>mdi-checkbox-blank-outline</v-icon> -->
                                    <v-checkbox
                                      v-model="item.entregado"
                                    ></v-checkbox>
                                    


                                    <v-list-item-content>
                                        <v-list-item-title v-text="`${item.descripcion} - ${item.codigo}`"></v-list-item-title>
                                        <!-- <v-list-item-subtitle v-text="`${item.precio} - ${item.codigo}`"></v-list-item-subtitle> -->
                                        <v-col md="3" class="pl-0">
                                            <v-text-field
                                            label="Precio + 10"
                                            v-model="item.precio"
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="2" class="pt-0">
                                          <v-checkbox
                                            v-model="item.es_cambio"
                                            label="Cambio"
                                          ></v-checkbox>
                                        </v-col>
                                        <v-col md="3" v-if="item.es_cambio">
                                          <v-text-field
                                            label="Precio del Devuelto + 10"
                                            v-model="item.cambio_precio"
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="3" v-if="item.es_cambio">
                                          <v-text-field
                                            label="Código Devuelto"
                                            v-model="item.cambio_codigo"
                                            dense
                                            ></v-text-field>
                                        </v-col>

                                    </v-list-item-content>
                                    
                                </v-list-item>
                                
                            </v-list>

                        </v-col>
                    
                    </v-row>
                </v-card-text>
                
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="error" dark @click="cerrarDialog" depressed>Salir</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="success" dark @click="grabarProductos" depressed>
                        <v-icon left>mdi-checkbox-marked-circle</v-icon>
                        <span>Grabar</span>
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>


        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
    </v-card>
</template>

<script>
import moment from 'moment'
import platform from 'platform-detect'
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
import DialogEspera from '@/components/DialogEspera.vue'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'ConfirmarVentas',
  mixins: [validationMixin],
  components: { SnackbarMensajes, DialogEspera },
  data: () => ({
    mayoreo: false,
    cancelado: false,
    totales_prefijos: [],
    dialogMercancia: false,
    dialogPrefijos: false,
    pedidos_notfound: [],
    repartidores: [],
    filtro_fecha_entrega: '',
    filtro_pedido: '',
    filtro_idrepartidor: '',
    filtro_vendedor: '',
    show_dp_filtro_fechaentrega: false,
    sb: {
      mostrar: false,
      mensaje: '',
      color: 'success',
      timeout: 3000,
      multiline: false,
    },
    menu_fecha_entrega: false,
    dialogEspera: false,
    dialogEditar: false,
    dialogEditarProducto: false,
    isLoading: false,
    grid_rows: [],
    totalgrid_rows: 0,
    editando: false,
    itemEliminar: {},
    editedItem: {
    },
    defaultItem: {
      id: -1,
      fecha: '',
      entrega_inmediata: true,
      fecha_entrega: '',
      cliente_nombre: '',
      cliente_apellidos: '',
      cliente_telefono: '',
      direccion_calle: '',
      direccion_numero: '',
      direccion_colonia: '',
      direccion_referencia: '',
      direccion_entrecalles: '',
      idvendedor: null,
      vendedor: {},
      vendedor_nombre: '',
      horaentrega_desde: '',
      horaentrega_hasta: '',
      comision_domicilio: '',
      total_pedido: '',
      observaciones: '',
      productos: [],
      productos_borrados: []
    },
    options: {
      sortBy: ['id'],
      sortDesc: [true]
    },
    headers: [
        { text: 'Pedido', value: 'id', sortable: true },
        { text: 'F. Captura', value: 'fecha', sortable: true},
        { text: 'Entrega para', value: 'fecha_entrega', sortable: false },
        { text: 'Vendedor', value: 'vendedor_nombre', sortable: true},
        { text: 'Repartidor', value: 'repartidor.nombre', sortable: false },
        { text: 'CxC', value: 'cuentaxcobrar', sortable: false, align:'center' },
        { text: 'Cliente', value: 'cliente_nombre', align: 'start', sortable: true },
        // { text: 'Opciones', value: 'actions', sortable: false },
    ],
    dt_prefijos_headers: [
      { text: 'Prefijo', value: 'prefijo', sortable: false, align: 'center'},
      { text: 'Importe', value: 'importe', sortable: false, align: 'right'},
    ],
    prods_options: {},
  }),
  computed: {
      totalCuentasXCobrar () {
        let total = 0;
        this.grid_rows.forEach((item, idx, array) => {
          if(typeof item.cuentaxcobrar === 'number') total += item.cuentaxcobrar;
        })
        return total;
      },
      entregables() {
          let arreglo = [];
            this.editedItem.productos.forEach((item,idx,arr) => {
                if(item.nosurtir == 0 && item.cancelado == 0 && item.codigo.length > 0) arreglo.push(item);
            });
          return arreglo;
      },
      productos_regresar() {
        let arreglo = [];
        this.grid_rows.forEach((itempedido, idx, array) => {
          // console.log(itempedido);
          itempedido.productos.forEach((item,idx,arr) => {
                  if(itempedido.cancelado == 1 || item.es_cambio || (item.nosurtir == 0 && item.cancelado == 0 && item.codigo.length > 0 && !item.entregado)) arreglo.push(item);
              });
         
        });
        return arreglo;
      },
      isDesktop() {
          return platform.desktop
      },
      formTitle() {
          const title = `Pedido #${this.editedItem.id}`;
          return platform.desktop ? title + ` - ${this.editedItem.vendedor_nombre } - R: ` + (this.editedItem.repartidor ? this.editedItem.repartidor.nombre : '') : title;
      },
      repartidor_nombre() {
          return 'R: '+ (this.editedItem.repartidor ? this.editedItem.repartidor.nombre : '')
      },
      resumen_pedido() {
          let subtitle = !platform.desktop ? `V: ${this.editedItem.vendedor_nombre}\nR: ` + (this.editedItem.repartidor ? this.editedItem.repartidor.nombre : '') + '\n' : '';
          let str = `${this.editedItem.cliente_nombre} ${this.editedItem.cliente_telefono}\n`;
          str = str + `${this.editedItem.direccion_calle} #${this.editedItem.direccion_numero} ${this.editedItem.direccion_entrecalles} COL. ${this.editedItem.direccion_colonia}`;
          str = str + (this.editedItem.observaciones ? this.editedItem.observaciones : '');
          return subtitle + str;
      },
    fechahoraPedido () {
        return this.editando ? `${this.editedItem.created_at}` : ''
    },

  },
  validations: {
    editedItem: {
      vendedor_nombre: { required, maxLength: maxLength(250) },
      cliente_nombre: { required, maxLength: maxLength(250) },
      cliente_telefono: { required, minLength: minLength(10), maxLength: maxLength(14) },
      direccion_calle: { required, maxLength: maxLength(250) },
      direccion_numero: { required, maxLength: maxLength(50) },
      direccion_colonia: { required, maxLength: maxLength(250) },
      direccion_entrecalles: { maxLength: maxLength(250) },
      productos: { required, minLength: minLength(1) },
    },
  },
  watch: {
    options: {
      handler(){
        //this.getDataFromApi();
      }
    },
  },
  methods: {
    clearFiltroPedido(event){
        this.filtro_pedido = "";
        this.getDataFromApi();
    },
    clearFiltroRepartidor(event){
        this.filtro_idrepartidor = null;
        this.getDataFromApi();
    },
    clearFiltroVendedor(event){
        this.vendedor_nombre = "";
        this.getDataFromApi();
    },
    itemSelected(item,options){
      this.editando = true;
      this.editedItem.productos = []
      this.editedItem = Object.assign({}, item);
      this.cancelado = item.cancelado == 1 ? true : false;
      this.mayoreo = item.mayoreo == 1 ? true : false;
      this.editedItem.productos_borrados = []
      this.dialogEditar = true;
    },
    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },
    grabarProductos: function() {
      const data = {
        idpedido: this.editedItem.id,
        productos: this.editedItem.productos,
        cancelado: this.cancelado ? 1 : 0,
        mayoreo: this.mayoreo ? 1 : 0,
        motivo_cancelacion: this.editedItem.motivo_cancelacion,
        borrados: []
      }

      ApiService.grabaProductosPedido(this.$store.getters.token,data)
          .then(response => {
              // 200 => OK Updated
              if(response.status == 200){
                this.getDataFromApi();
                this.showMensaje(`Pedido grabado ID: ${this.editedItem.id}`,'success',false)
                this.editando = false;
                this.dialogEditar = false;
                this.editedItem = Object.assign({}, this.defaultItem)
              }else{
                  this.showMensaje(`Error al grabar productos ${response.status} - ${response.statusText}`,'error',true)
              }
          })
          .catch(error => {
            this.showMensaje(`Error al grabar productos ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(() => {
            this.dialogEspera = false
          })
    },
    cerrarDialog () {
      this.dialogEditar = false
      this.getDataFromApi()
      this.editando = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    getDataFromApi() {
      this.isLoading = true

      if(!this.filtro_pedido) this.filtro_pedido = '';
      if(!this.search) this.search = '';
      if(!this.filtro_idrepartidor) this.filtro_idrepartidor = null;
      let filtros = {
        confirmado: 1,
        idpedido: this.filtro_pedido,
        vendedor_nombre: this.filtro_vendedor,
        idrepartidor: this.filtro_idrepartidor,
      }

      ApiService.getPedidosEntregas(this.$store.getters.token,this.options,filtros)
        .then(response => {
          if(response.status == 200){
            this.grid_rows = response.data.pedidos;
            this.pedidos_notfound = response.data.notfound;
            this.totales_prefijos = response.data.totales_prefijos;
            this.totalgrid_rows = parseInt(response.headers['x-pagination-total-count']);
            this.isLoading = false;
          }else{
            this.showMensaje(`Error al obtener listado ${response.status} - ${response.statusText} `,'error',true)
          }
        })
        .catch(error => {
          this.showMensaje(`Error al obtener listado ${error.response.status} - ${error.response.statusText}`,'error',true)
          this.isLoading = false
        })
        .finally(() => (this.isLoading = false))
    },
    getRepartidores() {
        this.dialogEspera = true
        
        ApiService.getRepartidores(this.$store.getters.token)
            .then(response => {
                if (response.status == 200) {
                    this.repartidores = response.data;
                    this.dialogEspera = false;
                } else {
                    this.showMensaje(
                        `Error al obtener listado ${response.status} - ${response.statusText} `,
                        'error', true)
                }
            })
            .catch(error => {
                if (error.response) this.showMensaje(
                    `Error al obtener listado ${error.response.status} - ${error.response.statusText}`,
                    'error', true)
                else this.showMensaje(`Error al obtener listado ${error.message}`, 'error', true)
                this.dialogEspera = false
            })
            .finally(() => (this.dialogEspera = false))
    },
  },
  mounted() {
    this.filtro_fecha_entrega =  moment().format('YYYY-MM-DD');
    this.getRepartidores();
  },
  created() {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
}
</script>
